class TooltipData {
  constructor() {
    this.trial = {
      Hubs: "Deep exhaustive insights on niche industries",
      "My Hubs": "Access your subscribed hubs",
      "Hubs catalogue": "Discover additional hubs to explore",
      Reports: "Access free report; explore ready and on-demand reports",
      "My Reports": "Full report available for your review",
      Library: "Preview thousands of ready-to-read reports",
      "On-Demand Reports": "Order custom, up-to-date market reports",
      Companies: "Quick company profiles from hubs",
      "Companies Covered": "Explore profiles of companies featured in hubs",
      "Recent Developments": "Key company news",
      "myRA AI": "5 trial credits to get Instant answers from library reports",
    };

    this.limited = {
      ...this.trial,
      Reports: "Access your subscribed reports; and browse ready and on-demand reports",
      "My Reports": "View your subscribed reports",
      Library: "Browse thousands of ready-to-read reports",
      "myRA AI": "Instant answers curated from thousands of library reports",
    };

    this.full = {
      ...this.limited,
    };

    return {
      trial: this.trial,
      limited: this.limited,
      full: this.full,
    };
  }
}

const toolTipData = new TooltipData();

export { toolTipData };

import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../Common/Notification";
import { Box, Typography, Button, LinearProgress } from "@mui/material";
import PasswordVisibilityInput from "../Common/PasswordVisibiltyInput";
import PasswordValidation from "../Common/PasswordValidation";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import API from "../../api/Api";

const LinkSendImg = 
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";
const driftImg = 
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/Vector_drift_design.svg";

const initialState = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const errorsState = {
  old_password: false,
  new_password: false,
  confirm_password: false,
  disabled: true,
};

function ChangePassword() {
  const { config, notify, setNotify } = useAuth();
  const [open, setOpen] = useState("RESET_PASSWORD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "0px",
        backButtonData: null, 
      },
    });
  }, [dispatch]);

  const resetPasswordFactory = {
    RESET_PASSWORD: <PasswordContainer {...{ config, setNotify, setOpen }} />,
    PASSWORD_CHANGE: <PasswordChangeContainer />,
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        sx={{
          marginTop: "2rem",
          height: "100%",
          minHeight: "45rem",
          width: "100%",
          padding: "3% 1%",
          background:
            "linear-gradient(92.47deg, rgba(24, 156, 222, 0.33) -39.01%, #EAFFEA -38.99%, #EAF6FE 50.05%, #E3E1FC 136.46%), linear-gradient(83.11deg, rgba(24, 156, 222, 0.33) -52.63%, #EAFFEA -52.61%, #EAF6FE 61.6%, #E3E1FC 172.45%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
        }}
      >
        <Box
          component="img"
          src={driftImg}
          alt="dft"
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        />
        {resetPasswordFactory[open] ?? <>No Data</>}
      </Box>
    </>
  );
}

const PasswordContainer = ({ config, setNotify, setOpen }) => {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState("IDLE");
  const { Base_URL } = API;

  const { old_password, new_password, confirm_password } = formState;

  const updateFormState = (name, value) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let updatedErrors = { ...errors };

    switch (fieldName) {
      case "old_password":
        updatedErrors.old_password = 
          value.trim().length <= 0 ? "Enter your current password" : false;
        break;

      case "new_password":
        updatedErrors.new_password = !value.trim() ? "New password is required" : false;
        break;

      case "confirm_password":
        updatedErrors.confirm_password = 
          value !== new_password ? "Passwords do not match" : false;
        break;

      default:
        break;
    }

    // Ensure the Save button gets enabled/disabled dynamically
    const isFormValid =
      !updatedErrors.old_password &&
      !updatedErrors.new_password &&
      !updatedErrors.confirm_password &&
      passwordValidation;

    updatedErrors.disabled = !isFormValid;
    setErrors(updatedErrors);
  };

  // Trigger confirm password re-check when password changes
  useEffect(() => {
    validateField("confirm_password", confirm_password);
  }, [new_password, confirm_password, passwordValidation]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");

    try {
      await axios.patch(
        `${Base_URL}/api/users/reset-password`,
        {
          old_password: old_password.trim(),
          new_password: new_password.trim(),
        },
        config
      );

      setNotify({
        isOpen: true,
        message: "Successfully reset the password",
        type: "success",
        position: "right",
      });
      setOpen("PASSWORD_CHANGE");
      setFormState(initialState);
      setErrors(errorsState);
    } catch (error) {
      setNotify({
        isOpen: true,
        message: error.response?.data?.message || "Some Error Occurred",
        type: "error",
        position: "right",
      });
    } finally {
      setLoading("IDLE");
    }
  };

  return (
    <Box
      width="444px"
      height="fit-content"
      padding="32px"
      bgcolor="#fff"
      borderRadius="12px"
      boxShadow="0px 2px 20px 0px #002F7514"
      zIndex="1"
    >
      <Box component="section">
        <Typography
          variant="h2"
          sx={{
            font: "800 24px / 36px Avenir Roman",
            color: "#191919",
          }}
        >
          Change Password
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBlock: "2rem",
          "& > div": {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            "& > label": {
              font: "800 14px / 21px Avenir Roman",
              color: "#2D2D2D",
            },
            "& > p": {
              color: "red",
              font: "400 14px / 21px Avenir Roman",
            },
          },
        }}
      >
        <Box>
          <label htmlFor="old_password">Current password</label>
          <PasswordVisibilityInput
            name="old_password"
            value={old_password}
            onChange={(e) => updateFormState("old_password", e.target.value)}
            placeholder="Enter current password"
          />
          {errors.old_password && (
            <Box component="p">{errors.old_password}</Box>
          )}
        </Box>
        <Box>
          <label htmlFor="new_password">New password</label>
          <PasswordVisibilityInput
            name="new_password"
            value={new_password}
            onChange={(e) => updateFormState("new_password", e.target.value)}
            placeholder="Enter new password"
          />
          <PasswordValidation
            password={new_password}
            setErrors={setPasswordValidation}
          />
        </Box>
        <Box>
          <label htmlFor="confirm_password">Confirm Password</label>
          <PasswordVisibilityInput
            name="confirm_password"
            value={confirm_password}
            onChange={(e) => updateFormState("confirm_password", e.target.value)}
            placeholder="Re-enter new password"
          />
          {errors.confirm_password && (
            <Box component="p">{errors.confirm_password}</Box>
          )}
        </Box>
        <Box>
          <Button
            type="submit"
            disabled={errors.disabled}
            sx={{
              marginTop: "2rem",
              marginBottom: "1rem",
              width: "100%",
              height: "52px",
              borderRadius: "8px",
              padding: "8px 24px",
              backgroundColor: errors.disabled ? "#D5D5D5" : "#189CDE",
              textTransform: "none",
              color: errors.disabled ? "#878787" : "#fff",
              font: "800 16px / 25.6px Avenir Roman",
              "&:hover": {
                backgroundColor: errors.disabled ? "#D5D5D5" : "#119CDF",
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      {loading === "PENDING" && <LinearProgress />}
    </Box>
  );
};

const PasswordChangeContainer = () => {
  return (
    <Box
      component="div"
      height="238px"
      width="504px"
      bgcolor="#fff"
      boxShadow="0px 2px 20px 0px #002F7514"
      borderRadius="8px"
      padding="24px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="1rem"
      zIndex="1"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
      >
        <Box
          component="img"
          src={LinkSendImg}
          alt="send"
          sx={{ width: "42.67px", height: "52.78px" }}
        />
        <Typography
          component="p"
          sx={{ font: "800 16px / 25.6px Avenir Roman" }}
        >
          Password changed successfully
        </Typography>
      </Box>
      <Box>
        <Link to="/reports">
          <Button
            sx={{
              bgcolor: "#189CDE",
              padding: "8px 24px",
              borderRadius: "8px",
              width: "456px",
              height: "52px",
              color: "#fff",
              font: "800 16px / 25.6px Avenir Roman",
              textTransform: "none",
              "&:hover": {
                bgcolor: "#189CDE",
              },
            }}
          >
            Go to Reports
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ChangePassword;
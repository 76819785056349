import { FormControlLabel, Checkbox, Box } from "@mui/material";
import { useReportsContext } from "../../STATE";

function ReportAccessByAlias() {
  const { state, dispatch } = useReportsContext();

  const handleReportAccessByAlias = (checked) => {
    dispatch({ type: "SET_REPORT_ACCESS_BY_ALIASES_FILTER", payload: checked });
  };
  
  return (
    <Box sx={{ pl: 2 }}>
      <FormControlLabel
        onClick={(e) => handleReportAccessByAlias(e.target.checked)}
        control={
          <Checkbox defaultChecked={state.reportAccessByAliasesFilter} />
        }
        label="Reports Accessed by Aliases"
      />
    </Box>
  );
}

export default ReportAccessByAlias;

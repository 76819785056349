import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useCompanyContext } from "../STATE";
import { Box, Tooltip } from "@mui/material";
import styles from "../styles.module.css";
import backArrow from "../../../assets/back_arrow_blue.svg";
import { TooltipComponent } from "../../../components/Tooltip";

function Layout({ children }) {
  const { state, dispatch } = useCompanyContext();

  const globalDispatch = useDispatch();
  useEffect(() => {
    globalDispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: state.selectedCompany ? "260px" : "248px",
      },
    });
  }, [state.selectedCompany]);

  const handleTabChange = (tab) => {
    dispatch({
      type: "CHANGE_TAB",
      payload: tab,
    });
  };

  const handleBackBtn = () => {
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    dispatch({ type: "RESET_PAGINATION" });
    dispatch({ type: "SET_SEARCH", payload: "" });
    dispatch({ type: "SET_SELECTED_CATEGORY", payload: null });
    dispatch({ type: "SET_SELECTED_COMPANY", payload: null });
  };

  return (
    <Box className={styles.wrapper}>
      <main className={styles.mainCompanyContainer}>
        <Box
          component="section"
          className={styles.topSection}
          sx={{ marginTop: state.selectedCompany ? "-120px" : "-90px" }}
        >
          {state.selectedCompany ? (
            <CompanyPath state={state} handleBackBtn={handleBackBtn} />
          ) : state.selectedCategory ? (
            <CategoryPath state={state} handleBackBtn={handleBackBtn} />
          ) : (
            <CompaniesTab state={state} handleTabChange={handleTabChange} />
          )}
        </Box>
        <Box
          component="section"
          className={styles.childrenContainer}
          sx={{
            filter: state.userAccessDenied ? "blur(5px)" : "none",
            pointerEvents: state.userAccessDenied ? "none" : "auto",
          }}
        >
          {children}
        </Box>
      </main>
    </Box>
  );
}

export default Layout;

const CompaniesTab = ({ state, handleTabChange }) => {
  const currentTab = state.currentTab;

  const tabs = [
    {
      name: `Companies Covered ${
        state.totalCompanies === 0 ? "" : `(${state.totalCompanies})`
      }`,
      value: "COMPANIES_COVERED",
      tooltip: "Companies Covered",
    },
    {
      name: "Recent Developments",
      value: "RECENT_DEVELOPMENT",
      tooltip: "Recent Developments",
    },
  ];

  return (
    <>
      {tabs.map((tab) => (
        <TooltipComponent tabName={tab.tooltip} key={tab.value}>
        <button
          key={tab.value}
          className={currentTab === tab.value ? `${styles.activeBtn}` : ""}
          onClick={() => handleTabChange(tab.value)}
        >
          {tab.name}
        </button>
        </TooltipComponent>
      ))}
    </>
  );
};

const CategoryPath = ({ state, handleBackBtn }) => {
  const TopHeading = ({ path }) => {
    const firstHead = "Companies Covered";
    const secHead = path.split("/").map((item) => {
      const a = item.charAt(0).toUpperCase();
      return a + item.substring(1, item.length);
    });
    return (
      <p>
        <Tooltip title="Go back">
          <span
            title="Go back"
            onClick={handleBackBtn}
            style={{ cursor: "pointer" }}
          >
            {firstHead}
          </span>
        </Tooltip>
        {secHead.join(" / ")}
      </p>
    );
  };

  return (
    <>
      <div className={styles.categoryPathContainer}>
        <section>
          <button onClick={handleBackBtn}>
            <img src={backArrow} alt="back" />
          </button>
          <div>
            <TopHeading path={state.selectedCategory.path} />
          </div>
        </section>
        <section>
          {state.selectedCategory.title + " Companies"}
          {state.loading === "IDLE" && ` (${state.totalCompanies ?? 0})`}
        </section>
      </div>
    </>
  );
};

const CompanyPath = ({ state, handleBackBtn }) => {
  const TopHeading = ({ path }) => {
    const firstHead = "Companies Covered";
    const secHead = path.split("/").map((item) => {
      const a = item.charAt(0).toUpperCase();
      return a + item.substring(1, item.length);
    });
    return (
      <p>
        <Tooltip title="Go back">
          <span
            title="Go back"
            onClick={handleBackBtn}
            style={{ cursor: "pointer" }}
          >
            {firstHead}
          </span>
        </Tooltip>
        {secHead.join(" / ")}
      </p>
    );
  };

  return (
    <>
      <div className={styles.categoryPathContainer}>
        <section>
          <button onClick={handleBackBtn}>
            <img src={backArrow} alt="back" />
          </button>
          <div>
            <TopHeading path={state.selectedCategory?.path || ""} />
          </div>
        </section>
        <section className={styles.companyInfoSection}>
          <div className={styles.selectedCompanyImg}>
            <img
              src={state.selectedCompany.logo}
              alt={state.selectedCompany.name}
            />
          </div>
          <div className={styles.aboutCompanySectionContainer}>
            <div>
              <h2>{state.selectedCompany.name}</h2>
            </div>
            <div>
              <ul className={styles.aboutCompanyDetails}>
                <li>
                  <strong>Est:</strong>{" "}
                  <span>{state.selectedCompany.founded}</span>
                </li>
                <li>
                  <strong>Country:</strong>{" "}
                  <span>{state.selectedCompany.country}</span>
                </li>
                <li>
                  <strong>Headquarters:</strong>{" "}
                  <span>{state.selectedCompany.headquarters}</span>
                </li>
                <li>
                  <strong>Annual Revenue:</strong>{" "}
                  <span>$ {state.selectedCompany.revenue} Million</span>
                </li>
                <li>
                  <strong>Employees:</strong>{" "}
                  <span>{state.selectedCompany.employees}</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

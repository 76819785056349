import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { RiSearchLine } from "react-icons/ri";
import { Container } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Common/Sidebar";
import HubsCard from "../../components/HubsComponents/HubsCard";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import SideDrawer from "../../components/Common/Drawer";
import { TabList } from "@mui/lab";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SynapseLogo from '../../assets/Synapse_logo.svg'

import API from "../../api/Api";
import { TooltipComponent } from "../../components/Tooltip";

function Hubs() {
  const [data, setData] = useState([]);
  const [hubsData, setHubsData] = useState(null);
  const [visibleItemLength, setVisibleItemLength] = useState(9);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hubLoading, setHubLoading] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const [nameBadge, setNameBadge] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [apiCallState, setApiCallState] = useState(1);
  const location = useLocation();
  const URL = location.pathname;
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [bookmarkCheckApi, setBookmarkCheckApi] = useState(0);
  // const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState("");
  const { config } = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentHubType, setCurrentHubType] = useState(
    URL === "/bookmarks" ? "yes" : "no"
  );
  const [isSticky, setIsSticky] = useState(false);
  const boxRef = useRef();

  const { Base_URL } = API;

  const heading = URL === "/bookmarks" ? "" : "Mordor Intelligence Hubs";
  const subHeading =
    URL === "/bookmarks" ? "" : "Dive Deeper, Collaborate Smarter with Synapse";
  const blueHeading =
    URL === "/bookmarks" ? "" : "Your All-in-One Market Intelligence Tool ";
  const paragraph =
    URL === "/bookmarks"
      ? ""
      : "Mordor Intelligence's Synapse empowers your entire team with one powerful, collaborative platform designed to conquer any market challenge. Whatever your question, Synapse unlocks the answers. Our deep industry expertise and dedicated analyst teams ensure you have the support you need to answer even the most niche business questions. ";

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: heading,
        paragraph: paragraph,
        backButtonData: null,
        bannerHeight: URL === "/bookmarks" ? "260px" : null,
        subHeading: subHeading,
        blueHeading: blueHeading,
      },
    });
  }, []);

  // useEffect(() => {
  //   if(URL === '/bookmarks'){
  //     setCurrentHubType("yes")
  //   }
  // }, [URL])

  const handleScroll = () => {
    const box = boxRef.current;
    if (box) {
      const boxTop = box.getBoundingClientRect().top;
      setIsSticky(boxTop <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentHubType(newValue);
  };

  const filterHub = hubsData?.filter((hub) =>
    hub.name.toLowerCase().includes(query.toLowerCase())
  );

  const [visibleItems, setVisibleItems] = useState(
    hubsData?.filter((hub) =>
      hub.name.toLowerCase().includes(query.toLowerCase())
    )?.length
  );

  useEffect(() => {
    // if (currentHubType === "yes") {
    setVisibleItems(hubsData?.length);
    // } else {
    // setVisibleItems(9);
  }, [hubsData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRemoveOption = (option, index) => {
    // Create a new array without the selected option
    const updatedOptions = subCategoryName.filter((item) => item !== option);
    // Update the selectedOptions array with the new array
    setSubCategoryName(updatedOptions);
    setNameBadge(updatedOptions);
    const number = apiCallState + 1;
    setApiCallState(number);

    const updatedSubCategory = subCategory
      .map((item, i) => (i !== index ? item : null))
      .filter((item) => item !== null);

    setSubCategory(updatedSubCategory);
  };

  // Here we are getting the on hubs page sideBar // categorys and subcategoryes DATA⬇️

  useEffect(() => {
    async function getInfo() {
      // setLoading(true);

      axios
        .get(`${Base_URL}/api/hubs/getCategorySubCategory?all=yes`, config)
        .then((res) => {
          const filteredData = res.data?.data.filter(
            (categoryItem) => categoryItem.subcategory.length > 0
          );
          setData(filteredData);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
    getInfo();
  }, []);

  // here we are clearing the sessionStorage
  useEffect(() => {
    // Remove an item from session storage
    sessionStorage.removeItem("hubsTabId");
    sessionStorage.removeItem("reportDimensions");
    sessionStorage.removeItem("region");
    sessionStorage.removeItem("companiesId");
    sessionStorage.removeItem("companies");
  }, []);

  const handleSeeMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  return (
    <Box>
      {URL !== "/bookmarks" && (
        <Box
          ref={boxRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "64px",
            boxShadow: "0 2px 20px 0 rgba(0, 47, 117, 0.08)",
            width: "100%",
            padding: "0px 24px 0px 24px",
            position: "sticky",
            top: "0",
            zIndex: 1,
            background: "white", // Set background color when sticky if needed
            gap: "8px",
          }}
        >
          <Box
            // ref={boxRef}
            className={`stickyLogo ${isSticky ? "sticky" : ""}`}
            sx={{
              display: isSticky ? { xs: "none", md: "flex" } : "none",
              justifyContent: "center",
              width: "150px",
              height: "64px",
              gap: "8px",
              position: "absolute",
              top: "10px",
              left: "0",
            }}
          >
            <Link to={"/reports"}>
              <img
                className="pic"
                style={{ width: "142px", height: "36px", marginLeft: "20px" }}
                src={SynapseLogo}
                // src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.png"
                alt="logo"
              />
            </Link>
          </Box>
          <Tabs
            value={currentHubType}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              sx={{
                paddingBottom: "0px",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "none",
              }}
              label={
                <TooltipComponent tabName="My Hubs">
                  My Hubs
                </TooltipComponent>
              }
              value="no"
              onClick={() => setVisibleItems(9)}
            />
            <Tab
              sx={{
                paddingBottom: "0px",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "none",
              }}
              label={
                <TooltipComponent tabName="Hubs catalogue">
                  Hubs Catalogue
                </TooltipComponent>
              }
              value="yes"
              onClick={() =>
                setVisibleItems(
                  hubsData?.filter((hub) =>
                    hub.name.toLowerCase().includes(query.toLowerCase())
                  )?.length
                )
              }
            />
          </Tabs>
        </Box>
      )}
      <Container maxWidth={"xl"}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container sx={{ marginTop: "50px" }}>
            {/* <Grid item xs={4} md={3}> */}
            {windowWidth >= 900 && (
              <Grid item md={3}>
                <Sidebar
                  data={data}
                  currentHubType={currentHubType}
                  setHubsData={setHubsData}
                  setMessage={setMessage}
                  setHubLoading={setHubLoading}
                  setSubCategoryName={setSubCategoryName}
                  subCategoryName={subCategoryName}
                  setNameBadge={setNameBadge}
                  nameBadge={nameBadge}
                  setSubCategory={setSubCategory}
                  subCategory={subCategory}
                  apiCallState={apiCallState}
                  bookmarkCheckApi={bookmarkCheckApi}
                  setBookmarkCheckApi={setBookmarkCheckApi}
                />
              </Grid>
            )}
            <Grid item xs={11} md={9}>
              <Box sx={{ ml: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="div"
                    id="hubs-search-field"
                    sx={{
                      border: "1px solid #D5D5D5",
                      borderRadius: "8px ",
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    {/* <IconButton
                    type="button"
                    sx={{ p: "10px", fontSize:"24px",color:"#002F75" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1, width: "80%"}}
                    placeholder="Search Hubs"
                        inputProps={{ "aria-label": "search " }}
                        value={query}
                    onChange={(e) => setQuery(e.target.value)}
                      />
                    <IconButton
                      type="button"
                      sx={{ p: "10px", fontSize:"24px",color:"#002F75" }}
                      aria-label="search"
                    >
                        <CloseOutlinedIcon onClick={() => { setQuery("")}} />
                    </IconButton> */}
                    <FormControl fullWidth>
                      <TextField
                        sx={{
                          "& .MuiInputBase-input": { borderRadius: "8px" },
                        }}
                        placeholder="Search Hubs"
                        variant="outlined"
                        fullWidth
                        value={query}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            setQuery("");
                          } else {
                            setQuery(e.target.value);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RiSearchLine
                                style={{ fontSize: "24px", color: "#002F75" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Box>
                  {windowWidth < 900 && (
                    <Grid item>
                      {/* <SideDrawer
                      data={data}
                      currentHubType={currentHubType}
                      setHubsData={setHubsData}
                      setMessage={setMessage}
                      setHubLoading={setHubLoading}
                    /> */}
                      <Sidebar
                        data={data}
                        currentHubType={currentHubType}
                        setHubsData={setHubsData}
                        setMessage={setMessage}
                        setHubLoading={setHubLoading}
                        // closeButton={<Button onClick={handleClose} sx={{color:"red"}}> X</Button>}
                        // handleClose={handleClose}
                      />
                    </Grid>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "12px",
                    marginTop: "20px",
                  }}
                >
                  {subCategoryName?.map((option, index) => (
                    <Box
                      key={option}
                      sx={{
                        borderRadius: "48px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "0.5px solid #1C937B",
                        background: "#D7F6EB",
                        height: "32px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          padding: "8px 0px 8px 12px",
                        }}
                      >
                        {option}
                      </Typography>
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveOption(option, index)}
                      >
                        <CloseOutlinedIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#2D2D2D",
                          }}
                        />
                      </button>
                    </Box>
                  ))}
                </Box>
                <Box my={5} display="flex" flexWrap="wrap"  sx={{gap : { xl: 5 , lg: 3}}}>
                  {filterHub?.slice(0, visibleItems).map((item) => (
                    <Box key={item.id}>
                      <HubsCard
                        {...item}
                        setBookmarkCheckApi={setBookmarkCheckApi}
                        bookmarkCheckApi={bookmarkCheckApi}
                      />
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {filterHub && visibleItems < filterHub.length ? (
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "none",
                          boxShadow: "none",
                          color: "#189CDE",
                          fontWeight: "800",
                          fontSize: "16px",
                          lineHeight: "25.6px",
                        }}
                        onClick={handleSeeMore}
                      >
                        See More Hubs <KeyboardArrowDownIcon />
                      </Button>
                    ) : filterHub?.length > 9 ? (
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "none",
                          boxShadow: "none",
                          color: "#189CDE",
                          fontWeight: "800",
                          fontSize: "16px",
                          lineHeight: "25.6px",
                        }}
                        onClick={() => setVisibleItems(9)}
                      >
                        See Less Hubs <KeyboardArrowUpIcon />
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box>
                    {filterHub?.length === 0 && !message && (
                      <Box
                        width="600px"
                        height="300px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {hubLoading ? (
                          <CircularProgress />
                        ) : (
                          "There is no hub found"
                        )}
                      </Box>
                    )}
                  </Box>

                  <Box
                    width="600px"
                    height="300px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {message}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}

export default Hubs;

import { Tooltip } from "@mui/material";
import { toolTipData } from "./tooltip-data";
import { useSelector } from "react-redux";

function TooltipComponent({ tabName, children }) {
  const { access_type } = useSelector((state) => state.userData);
  if(!access_type) {
    return children;
  }
  const setTooltipData = (tabName) => {
    return toolTipData[access_type][tabName] ?? "Click Here";
  }

  return (
    <Tooltip title={setTooltipData(tabName)} arrow>
      {children}
    </Tooltip>
  );
}

export { TooltipComponent };
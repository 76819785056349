import { useState, useEffect } from "react";
import { useReportsContext } from "../../STATE";
import filtersImg from "../../../../assets/filters.svg";
import styles from "../../styles.module.css";
import FilterModal from "./FilterModal";
import RegionCountries from "./RegionCountries";
import CatalogueInstantAccess from "./CatalogueInstantAccess";
import { getFilters } from "./helper";
import { Divider } from "@mui/material";
import LibraryReportPublishYear from "./LibraryReportPublishYear";
import LibraryReportStatus from "./LibraryReportStatus";
import { useAuth } from "../../../../context/authContext/Auth";
import { cannotShowForThisUser } from "../../reports.utils";
import ReportAccessByAlias from "./ReportAccessByAlias";

function Filter() {
  const { state, dispatch } = useReportsContext();
  const { userInfo } = useAuth();

  const [dontShowFilterForThisUser, setDontShowFilterForThisUser] = useState(true);
  useEffect(() => {
    if (state.currentTab === "REPORTS_LIBRARY") {
      setDontShowFilterForThisUser(cannotShowForThisUser(userInfo));
    } else {
      setDontShowFilterForThisUser(false);
    }
  }, [state.currentTab, userInfo?.email]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({ regions: [], countries: [], count: {} });

  useEffect(() => {
    dispatch({
      type: "SET_FILTER_MODAL_LOADING",
      payload: "PENDING",
    });
    getFilters({
      setFilters,
      category: state.sideBarCategory.currentCategory,
      dispatch,
      state,
    });
  }, [state.sideBarCategory.currentCategory, state.isLibraryHubReport, state.onlyHubReportsForMyReports, state.isLibraryReports, state.currentTab]);

  if (dontShowFilterForThisUser) {
    return <></>;
  }

  return (
    <div className={styles.mainFilterContainer}>
      <button onClick={() => setOpenFilter((p) => !p)}>
        <img src={filtersImg} alt="filter" />
        Filter
      </button>
      {openFilter && (
        <FilterModal setOpenFilter={setOpenFilter}>
          <FiltersToShowBasedOnTab
            currentTab={state.currentTab}
            filters={filters}
            isLibraryReports={state.isLibraryReports}
          />
        </FilterModal>
      )}
    </div>
  );
}

export default Filter;

const FiltersToShowBasedOnTab = ({ currentTab, filters, isLibraryReports }) => {
  if (currentTab === "REPORTS_LIBRARY") {
    return (
      <>
        <RegionCountries filters={filters} />
        <Divider />
        <LibraryReportPublishYear />
        <Divider />
        <LibraryReportStatus />
        <Divider />
        <ReportAccessByAlias />
      </>
    );
  } else if (currentTab === "CATALOGUE") {
    return (
      <>
        <RegionCountries filters={filters} />
        <CatalogueInstantAccess />
      </>
    );
  } else if (isLibraryReports && currentTab === "MY_REPORTS") {
    return (
      <>
        <RegionCountries filters={filters} />
        <Divider />
        <LibraryReportPublishYear />
        <Divider />
        <ReportAccessByAlias />
      </>
    );
  } else {
    return (
      <>
        <RegionCountries filters={filters} />
        <Divider />
        <ReportAccessByAlias />
      </>
    );
  }
};

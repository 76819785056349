import parse, { domToReact } from "html-react-parser";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import styles from "./styles.module.css";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { GetAccess } from "../../../pages/ReportDetails/ReportBriefDetail";

export default function ParsedReportPage({ HTML, setDrawerOpen, fetchReport, reportData, template = null }) {
  const [openAccordion, setOpenAccordion] = useState(false);
  const { selectedReportDetail, remaining_ondemand_credit } = useSelector((state) => state);

  const isReportSubscribed = selectedReportDetail.subscribed === "yes";

  const handleAccordion = (e) => {
    e.stopPropagation();
    setOpenAccordion((p) => !p);
  };

  const options = createOptions({
    openAccordion,
    handleAccordion,
    isReportSubscribed,
    setDrawerOpen,
    remaining_ondemand_credit,
    fetchReport,
    report_id: reportData.report_id,
    is_requested_catalogue_report: reportData.is_requested_catalogue_report,
    title: reportData.title,
    template,
  });

  useEffect(() => {
    document.getElementsByClassName("about-report-menu")[0] &&
      document.getElementsByClassName("heading-title") &&
      handleTableScroll(
        document.getElementsByClassName("about-report-menu")[0].children,
        document.getElementsByClassName("heading-title"),
        template,
      );
    document.getElementById("clicked-on-toc") &&
      handleTableScroll(
        [document.getElementById("clicked-on-toc")],
        document.getElementsByClassName("customize-toc"),
        template
      );

    const container = document.getElementsByClassName(
      "flex container preview-rd-page"
    )[0];
    if (container) {
      container.style.height = `100%`;
      container.style.position = "relative";
    }

    const openAcc = setTimeout(() => {
      setOpenAccordion(true);
    }, 3000);

    return () => {
      clearTimeout(openAcc);
    };
  }, []);

  return parse(HTML, options);
}

// Modify attributes
const createOptions = (config) => {
  const { openAccordion, handleAccordion, setDrawerOpen, isReportSubscribed, remaining_ondemand_credit, fetchReport, is_requested_catalogue_report, report_id, title, template } =
    config;
  return {
    replace(domNode) {
      const { attribs, children, name } = domNode;

      if (!attribs) return;

      if (attribs.class && classesToRemove.includes(attribs.class.trim()))
        return <></>;
      if (attribs.id && idsToRemove.includes(attribs.id.trim())) return <></>;

      // // styles

      if (attribs.id === "component-heading-marketsnapshot-1") {
        return (
          <h1 className={styles.reportTitle}>{domToReact(children, config)}</h1>
        );
      }

      if (attribs.class && attribs.class === "opacity-toggle") {
        attribs.style = "margin:2rem 0";
      }

      if (attribs.class === "about-report-link") {
        return (
          <p className={styles.aboutReportLink} onClick={handleAccordion}>
            About this Report
            <span>
              <IconButton className={styles.iconButton} onClick={handleAccordion}>
                {openAccordion ? (
                  <ExpandLess style={{ color: "#189CDE" }} />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </span>
          </p>
        );
      }

      if (attribs.id === "download-sample-left") {
        if (isReportSubscribed) return <></>;
        if (remaining_ondemand_credit === 0) {
          return (
            <button
              id="download-sample-left"
              className={styles.buyNowButton}
              onClick={() => setDrawerOpen(true)}
            >
              Buy Now!
            </button>
          );
        } else if (is_requested_catalogue_report) {
          return (
            <span
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: "6px",
                backgroundColor: "rgba(0,255,0, .25)",
                color: "rgb(8, 71, 8)",
              }}
            >
              Requested
            </span>
          );
        } else {
          return (
            <div className={styles.getAccessBtnCnt}>
              <GetAccess
                fetchReport={fetchReport}
                remaining_ondemand_credit={remaining_ondemand_credit}
                is_requested_catalogue_report={is_requested_catalogue_report}
                report_id={report_id}
                subscribed={isReportSubscribed ? "yes" : "no"}
                title={title}
                mainBtnStyles={{
                  padding: "10px 30px !important",
                  width: "100% !important",
                }}
              />
            </div>
          );
        }
      }

      if (openAccordion) {
        if (attribs.class === "about-report-menu") {
          return (
            <ul
              className={`show-about-report-menu ${styles.minorList}`}
              style={{ marginTop: openAccordion ? "1rem" : 0 }}
            >
              {domToReact(children, createOptions(config))}
            </ul>
          );
        }
      } else {
        if (attribs.class === "about-report-menu show-about-report-menu") {
          return (
            <ul className={`${styles.minorList}`}>
              {domToReact(children, createOptions(config))}
            </ul>
          );
        }
      }

      if (attribs.class === "rd-page-navigation") {
        return (
          <nav className={template === "extended-preview-new-e" ? styles.templateNavContainer : styles.navContainer}>
            {domToReact(children, createOptions(config))}
          </nav>
        );
      }

      if (
        attribs.class === "main-nav-list" ||
        attribs.class === "main-nav-list-lorien-rd"
      ) {
        return (
          <ul className={styles.mainNavList}>
            {domToReact(children, createOptions(config))}
          </ul>
        );
      }

      if (attribs.class === "nav-links-rd" && name === "li") {
        if (
          [
            "LIST OF TABLES & FIGURES",
            "SCOPE OF THE REPORT",
            "Frequently Asked Questions",
            "MARKET DEFINITION",
            "RESEARCH METHODOLOGY",
          ].includes(children[0].data.trim())
        )
          return <></>;
        if (attribs.id === "clicked-on-toc") {
          return (
            <li
              className={`${attribs.class} ${template === "extended-preview-new-e" ? styles.templateNavListItem : styles.navListItem}`}
              id={attribs.id}
            >
              Table of Contents
            </li>
          );
        }
        return (
          <li
            className={`${attribs.class} ${template === "extended-preview-new-e" ? styles.templateNavListItem : styles.navListItem}`}
            id={attribs.id}
          >
            {domToReact(children, createOptions(config))}
          </li>
        );
      }

      if ((attribs.class && attribs.class.includes("component-heading")) || attribs.id?.includes("competitive_landscape")) {
        return (
          <h2
            id={attribs.id}
            className={`${attribs.class} ${styles.headingTitle} heading-title`}
          >
            {domToReact(children, createOptions(config))}
          </h2>
        );
      }

      if (attribs.class === "buy-from-us") {
        return (
          <h3 id={attribs.id} className={`${styles.minorHeading}`}>
            {domToReact(children, createOptions(config))}
          </h3>
        );
      }

      if (attribs.class === "block-heading") {
        return (
          <h3 className={`${styles.blockHeading}`}>
            {domToReact(children, createOptions(config))}
          </h3>
        );
      }
    },
  };
};

const handleTableScroll = (sideBar, components, template) => {
  const sideBarList = Object.values(sideBar);
  sideBarList.forEach((li, index) => {
    const matchedItem = findMatchingComponentForAccordion(
      sideBarHeadingRelationship[li.innerText.trim().toLowerCase()],
      Object.values(components)
    );
    // console.log(matchedItem, sideBarHeadingRelationship[li.innerText.trim().toLowerCase()]);
    if (matchedItem) {
      const idToScroll = findIdToScroll(matchedItem, template);
      if (idToScroll)
        li.addEventListener("click", () => scrollToSection(idToScroll));
    }
  });
};

const findMatchingComponentForAccordion = (reqItem, list) => {
  if (!reqItem) return null;
  const testThis = new RegExp(reqItem, "i");
  for (let i = 0; i < list.length; i++) {
    if(!list[i]) continue;
    if (testThis.test(list[i].innerText.trim())) {
      return list[i];
    }
  }
  return null;
};

const findIdToScroll = (comp, template = null) => {
  // console.log(comp.id);
  if (!comp) return null;
   if (comp.id) {
    return comp.id;
  } else {
    return findIdToScroll(comp.parentElement, template);
  }
};

const scrollToSection = (section) => {
  const sectionElement = document.getElementById(section);

  if (sectionElement) {
    // Get the bounding rect of the section
    const rect = sectionElement.getBoundingClientRect();
    // Scroll to the top of the section with a smooth behavior
    window.scrollTo({
      top: rect.top - 70 + window.scrollY, // Adjust the scroll position here
      behavior: "smooth",
    });
  }
};

const classesToRemove = [
  "site-header-wrapper",
  "flex rd-heading-section rd-banner",
  "row breadcrumb text-center",
  "container faqsection-font",
  "container",
  "know-more",
  "fixed-bottom-div",
  "flex submit-customize-form-section",
  "price-breakup",
  "demo-class",
  "listoffigures",
  "fixed-customize-form",
  "close-fixed-customize-form",
];

const idsToRemove = [
  "keysellingpoints",
  "footer",
  "related-reports-section",
  "seo-text-section",
  "initial-banner",
  // "download-sample-left",
  "key-market-trends-download-sample",
  "geography-trends-form",
  "market-definition-cutomize",
  "research-methodology-customize",
  "scopeof-the-report-customize",
  "share-button",
  "key-market-trends-download-sample-selected-indicators-1-0",
  "key-market-trends-download-sample-selected-indicators-0-0",
  "market-definition",
  "research-methodology",
  "scope-of-the-report",
  "faqs",
  "scope-of-the-report-bottom",
  "market-concentration-download",
  "get-price-break-up",
  "tables-and-figures",
];

const sideBarHeadingRelationship = {
  "market snapshot": "market size",
  "market overview": "market analysis",
  "key market trends": "trends",
  "competitive landscape": "industry overview",
  "major players": "market leaders",
  "recent developments": "market news",
  "free with this report": "free with this report",
  "table of contents": "table of contents",
  "segment analysis": "segment analysis",
  "geography analysis": "geography segment analysis",
};

import { FormControlLabel, Switch } from "@mui/material";
import { usePurchaseHistoryCtx } from "../STATE";

function AliasAccessReportsSwitch() {
  const {
    state: { isAliasAccessedReports },
    dispatch,
  } = usePurchaseHistoryCtx();
  const handleLibraryChecked = (checked) => {
    dispatch({ type: "GET_ALL_ALIAS_ACCESSED_REPORTS", payload: checked });
  };
  return (
    <FormControlLabel
      control={<Switch />}
      label="Show Alias Accessed Reports"
      checked={isAliasAccessedReports}
      onChange={(e) => handleLibraryChecked(e.target.checked)}
    />
  );
}

export default AliasAccessReportsSwitch;

import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../../components/Common/Notification";
import DialogModal from "../../components/Common/DialogModal";
import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import API from "../../api/Api";
import LabelInputField from "../../components/Common/LabelInputField";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import PasswordValidation from "../../components/Common/PasswordValidation";
import ValidateIfTheEmailCanBeUsedForRegistration from "./helper";

const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";

const initialState = {
  name: "",
  email: "",
  password: "",
  confirm_password: "",
  promo_code: "",
};

const errorsState = {
  ...initialState,
  password: false,
  confirm_password: false,
  disabled: true,
};

function Register() {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState("IDEAL");
  const [emailValidation, setEmailValidation] = useState({ isValid: false, message: '' });
  const [open, setOpen] = useState(false);
  const { notify, setNotify } = useAuth();
  const { Base_URL } = API;

  const { email, name, password, confirm_password, promo_code } = formState;

  // Update errors and formState dynamically
  const updateFormState = (name, value) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let updatedErrors = { ...errors };

    switch (fieldName) {
      case "email":
        updatedErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())
          ? false
          : "Invalid Email";
        break;

      case "name":
        updatedErrors.name =
          value.trim().length >= 3 ? false : "Invalid Name, minimum 3 characters";
        break;

      case "password":
        updatedErrors.password = !value.trim() ? "Password is required" : false;
        break;

      case "confirm_password":
        updatedErrors.confirm_password =
          value === password ? false : "Passwords do not match";
        break;

      case "promo_code":
        updatedErrors.promo_code = false; // No validation needed
        break;

      default:
        break;
    }

    // Ensure the Register button gets enabled/disabled dynamically
    const isFormValid =
      !(updatedErrors.name === "") &&
      emailValidation.isValid &&
      !updatedErrors.email &&
      !updatedErrors.name &&
      !updatedErrors.password &&
      !updatedErrors.confirm_password &&
      passwordValidation;

    updatedErrors.disabled = !isFormValid;
    setErrors(updatedErrors);
  };

  // Trigger confirm password re-check when password changes
  useEffect(() => {
    validateField("confirm_password", confirm_password);
  }, [password, confirm_password, passwordValidation, emailValidation, email]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");

    try {
      await axios.post(`${Base_URL}/api/users/register`, {
        email: email.trim(),
        name: name.trim(),
        password: password.trim(),
        promo_code: promo_code.trim(),
      });

      setOpen(true);
      setFormState(initialState);
      setErrors(errorsState);
    } catch (error) {
      setNotify({
        isOpen: true,
        message: error.response?.data?.message || "Some Error Occurred",
        type: "error",
        position: "right",
      });
    } finally {
      setLoading("IDEAL");
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <div className={styles.detailsBox}>
        <h1>Registration</h1>
        <div className={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <LabelInputField>
              <LabelInputField.Label>
                Name
                <LabelInputField.Tooltip value="Enter 3 characters minimum" />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="name"
                value={name}
                placeholder="Enter your name"
                onChange={(e) => updateFormState("name", e.target.value)}
              />
              <LabelInputField.Error errors={errors.name} />
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Email
                <LabelInputField.Tooltip value="Enter a valid email address" />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => {setEmailValidation({ isValid: false, message: '' }); updateFormState("email", e.target.value)}}
              />
              <LabelInputField.Error errors={errors.email} />
              {!errors.email && <ValidateIfTheEmailCanBeUsedForRegistration email={email} setEmailValidation={setEmailValidation} />}
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Promo Code
                <LabelInputField.Tooltip value="Enter promo code (optional)" />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="promo_code"
                value={promo_code}
                placeholder="Enter your promo code"
                onChange={(e) => updateFormState("promo_code", e.target.value)}
              />
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Password
                <LabelInputField.Tooltip value="Enter a valid password" />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => updateFormState("password", e.target.value)}
                />
              </LabelInputField.CustomInput>
              <PasswordValidation
                password={password}
                setErrors={setPasswordValidation}
              />
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Confirm Password
                <LabelInputField.Tooltip value="Confirm your password" />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm your password"
                  value={confirm_password}
                  onChange={(e) =>
                    updateFormState("confirm_password", e.target.value)
                  }
                />
              </LabelInputField.CustomInput>
              <LabelInputField.Error errors={errors.confirm_password} />
            </LabelInputField>

            <div className={styles.loginContainer}>
              <button type="button" className={styles.backBtn}>
                <Link to="/">Back to Login</Link>
              </button>
              <button
                type="submit"
                disabled={errors.disabled}
                className={errors.disabled ? `${styles.disabledBtn}` : ""}
              >
                Register
              </button>
            </div>
            {loading === "PENDING" && <LinearProgress />}
          </form>
        </div>
      </div>

      <DialogModal open={open} handleClose={() => setOpen(false)}>
        <div className={styles.dialogModal}>
          <figure>
            <img src={LinkSendImg} alt="Confirmation Link Sent" />
          </figure>
          <h4>Confirmation link sent to your email</h4>
        </div>
      </DialogModal>
    </>
  );
}

export default Register;

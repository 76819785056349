import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import API from "../../api/Api";
import { Box } from "@mui/material";

const ValidateIfTheEmailCanBeUsedForRegistration = ({
  email,
  setEmailValidation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const debouncedEmail = useDebounce(email, 1800);

  useEffect(() => {
    // Reset states when email changes
    setError(null);
    setEmailValidation(false);

    if (!debouncedEmail) {
      return;
    }

    const validateEmail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${API.Base_URL}/api/users/validate-email`,
          {
            email: debouncedEmail.trim(),
          }
        );

        if (response.status >= 400) {
          setEmailValidation({
            isValid: false,
            message: response.data.message,
          });
        } else {
          // If we reach here, the email is valid
          setEmailValidation({ isValid: true, message: "" });
          setError(null);
        }
      } catch (err) {
        setEmailValidation({
          isValid: false,
          message: err.response?.data?.message,
        });
        // Extract error message from the response
        const errorMessage =
          err.response?.data?.message || "Failed to validate email";
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    validateEmail();
  }, [debouncedEmail, setEmailValidation]);

  if (!email) {
    return null;
  }

  return (
    <div className="mt-2">
      {isLoading && (
        <Box
          component="p"
          sx={{
            color: "info.main",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Checking email...
        </Box>
      )}

      {error && (
        <Box
          component="p"
          sx={{
            color: "error.main",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {error}
        </Box>
      )}

      {/* {!isLoading && !error && email && (
        <Box
          component="p"
          sx={{
            color: "success.main",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Email is valid
        </Box>
      )} */}
    </div>
  );
};

export default ValidateIfTheEmailCanBeUsedForRegistration;

import styles from "./styles.module.css";
import backArrow from "../../assets/back_arrow_blue.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useReportsContext, getReports, sideBarCategoryInitialState } from "./STATE";
import { Tooltip } from "@mui/material";
import { getTabBasedOnLibrary } from "./reports.utils";
import { TooltipComponent } from "../../components/Tooltip";

function ReportsLayout({ children }) {
  const { state, dispatch, setParams } = useReportsContext();

  const clearPathSearchParam = (tab) => {
    setParams("path", "");
    setParams("tab", tab);
    setParams("limit", "10");
    setParams("page", "1");
  }

  const Dispatch = useDispatch();
  useEffect(() => {
    Dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "238px",
      },
    });
  }, []);

  const handleTabChange = (tab) => {
    setParams("tab", tab);
    dispatch({
      type: "CHANGE_TAB",
      payload: tab,
      fn: async (state) => {
        dispatch({
          type: "SET_SIDEBAR_CATEGORY",
          payload: sideBarCategoryInitialState,
          fn: () => clearPathSearchParam(tab)
        })
        await getReports(state, dispatch)
      }
    });
  };

  const handleBackBtn = () => {
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    dispatch({ type: `SET_${getTabBasedOnLibrary(state.currentTab, state.isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "SET_SIDEBAR_CATEGORY",
      payload: sideBarCategoryInitialState,
      fn: async (state) => {
        clearPathSearchParam(state.currentTab)
        await getReports(state, dispatch);
      },
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <main className={styles.mainReportContainer}>
          <section className={styles.topSection}>
            {state.sideBarCategory.currentCategory ? (
              <CategoryPath handleBackBtn={handleBackBtn} state={state} />
            ) : (
           
              <ReportTab handleTabChange={handleTabChange} state={state} />
            )}
          </section>
          <section className={styles.mainChildrenSection}>{children}</section>
        </main>
      </div>
    </>
  );
}

export default ReportsLayout;

const ReportTab = ({ handleTabChange, state }) => {
  const setToolTipText = (tab, name) => {
    if (tab === state.currentTab) return `Reset ${name} Results`;
    return `Switch to ${name}`;
  };

  const tabs = {
    "MY_REPORTS" : { loading: state.isLibraryReports ? state.archiveLoading : state.myReportsLoading, count: state.totalMyReports },
    "REPORTS_LIBRARY": { loading: state.archiveLoading, count: state.totalArchiveReports },
    "CATALOGUE" : { loading: state.catalogueLoading, count: state.totalCatalogueReports },
  }

  const setBtnTxt = (name, tab) => {
    const currentLoading = tabs[tab].loading;
    const count = tabs[tab].count;
    const isLoading = tab === state.currentTab && currentLoading === "PENDING";
    const btnTxt = `${name} ${
      isLoading === false && count > 0 ? "(" + count + ")" : ""
    }`
    return btnTxt;
  };

  return (
    <>
      {primarySections.map((btn) => (
        <TooltipComponent tabName={btn.tooltip} key={btn.key}>
          <button
            key={btn.key}
            className={
              state.currentTab === btn.key ? `${styles.activeBtn}` : ""
            }
            onClick={() => handleTabChange(btn.key)}
          >
            {setBtnTxt(btn.name, btn.key)}
          </button>
        </TooltipComponent>
      ))}
    </>
  );
};
  
const CategoryPath = ({ handleBackBtn, state }) => {
  const issueTab = {
    "MY_REPORTS": { title: "My Reports", count: state.totalMyReports },
    "REPORTS_LIBRARY": { title: "Library", count: state.totalArchiveReports },
    "CATALOGUE": { title: "On-Demand Reports", count: state.totalCatalogueReports }
  }
  const TopHeading = ({ path }) => {
    const firstHead = issueTab[state.currentTab].title ?? ""
    const secHead = path.split("/").map((item) => {
      const a = item.charAt(0).toUpperCase();
      return a + item.substring(1, item.length);
    });
    return (
      <p>
        <Tooltip title="Go back">
          <span
            title="Go back"
            onClick={handleBackBtn}
            style={{ cursor: "pointer" }}
          >
            {firstHead}
          </span>
        </Tooltip>
        {secHead.join(" / ")}
      </p>
    );
  };
  return (
    <>
      <div className={styles.categoryPathContainer}>
        <section>
          <button onClick={handleBackBtn}>
            <img src={backArrow} alt="back" />
          </button>
          <div>
            <TopHeading path={state.sideBarCategory.path} />
          </div>
        </section>
        <section>
          {state.sideBarCategory.title + " Reports"}
          {state.loading === "IDLE" && ` (${issueTab[state.currentTab].count ?? 0})` }
        </section>
      </div>
    </>
  );
};

const primarySections = [
  {
    name: "My Reports",
    key: "MY_REPORTS",
    isPublic: false,
    tooltip: "My Reports"
  },
  {
    name: "Library",
    key: "REPORTS_LIBRARY",
    isPublic: true,
    tooltip: "Library",
  },
  {
    name: "On-Demand Reports",
    key: "CATALOGUE",
    isPublic: false,
    tooltip: "On-Demand Reports"
  },
];

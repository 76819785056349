import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../../components/Common/Notification";
import DialogModal from "../../components/Common/DialogModal";
import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import PasswordValidation from "../../components/Common/PasswordValidation";
import { useParams, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import API from "../../api/Api";
import LabelInputField from "../../components/Common/LabelInputField";
import styles from "./styles.module.css";

const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";

const initialState = {
  password: "",
  confirm_password: "",
};

const errorsState = {
  password: false,
  confirm_password: false,
  disabled: true,
};

function NewPassword() {
  const { key } = useParams();
  const navigate = useNavigate();

  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState("IDLE");
  const [open, setOpen] = useState(false);
  const { notify, setNotify } = useAuth();
  const { Base_URL } = API;

  const { password, confirm_password } = formState;

  const updateFormState = (name, value) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let updatedErrors = { ...errors };

    switch (fieldName) {
      case "password":
        updatedErrors.password = !value.trim() ? "Password is required" : false;
        break;

      case "confirm_password":
        updatedErrors.confirm_password = 
          value !== password ? "Passwords do not match" : false;
        break;

      default:
        break;
    }

    // Ensure the Save button gets enabled/disabled dynamically
    const isFormValid =
      !updatedErrors.password &&
      !updatedErrors.confirm_password &&
      passwordValidation;

    updatedErrors.disabled = !isFormValid;
    setErrors(updatedErrors);
  };

  // Trigger confirm password re-check when password changes
  useEffect(() => {
    validateField("confirm_password", confirm_password);
  }, [password, confirm_password, passwordValidation]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");

    try {
      await axios.post(`${Base_URL}/api/users/forgot-password/${key}`, {
        password: password.trim(),
      });

      setOpen(true);
      setFormState(initialState);
      setErrors(errorsState);

      setTimeout(() => {
        navigate("/", { replace: true });
      }, 3000);
    } catch (error) {
      setNotify({
        isOpen: true,
        message: error.response?.data?.message || "Some Error Occurred",
        type: "error",
        position: "right",
      });
    } finally {
      setLoading("IDLE");
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <div className={styles.detailsBox}>
        <h1>Set a New Password</h1>
        <div className={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <LabelInputField>
              <LabelInputField.Label>
                New Password
                <LabelInputField.Tooltip value="Enter valid password" />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => updateFormState("password", e.target.value)}
                />
              </LabelInputField.CustomInput>
              <PasswordValidation
                password={password}
                setErrors={setPasswordValidation}
              />
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Confirm Password
                <LabelInputField.Tooltip value="Confirm the password" />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm your password"
                  value={confirm_password}
                  onChange={(e) => updateFormState("confirm_password", e.target.value)}
                />
              </LabelInputField.CustomInput>
              {errors.confirm_password && (
                <LabelInputField.Error errors={errors.confirm_password} />
              )}
            </LabelInputField>

            <div className={styles.loginContainer}>
              <button
                type="submit"
                disabled={errors.disabled}
                className={errors.disabled ? `${styles.disabledBtn}` : ""}
              >
                Save Password
              </button>
            </div>
            {loading === "PENDING" && <LinearProgress />}
          </form>
        </div>
      </div>

      <DialogModal open={open} handleClose={() => setOpen(false)}>
        <div className={styles.dialogModal}>
          <figure>
            <img src={LinkSendImg} alt="sent" />
          </figure>
          <h4>Password created successfully!</h4>
          <p>Redirecting to Login...</p>
        </div>
      </DialogModal>
    </>
  );
}

export default NewPassword;
import { createContext, useContext } from "react";
import { getModifiedReportCount, getModifiedReports } from "./helper";

const paginationInitialState = {
  page: 1,
  limit: 10,
};

export const initialState = {
  loading: "PENDING",
  everyReports: [],
  reports: [],
  search: "",
  isLibraryReports: false,
  isAliasAccessedReports: false,
  filterType: null,
  sortType: null,
  selectedTab: "ALL",
  count: {
    all: 0,
    renewal: 0,
    expired: 0,
  },
  pagination: paginationInitialState,
};

export const reducer = (state, action) => {
  let newState = {};

  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_REPORTS":
      newState = {
        ...state,
        everyReports: action.payload,
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
        count: getModifiedReportCount(newState),
      };
    case "SET_SEARCH":
      newState = {
        ...state,
        pagination: paginationInitialState,
        search: action.payload,
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
        count: getModifiedReportCount(newState),
      };
    case "SET_IS_LIBRARY_REPORTS":
      return {
        ...initialState,
        isAliasAccessedReports: state.isAliasAccessedReports,
        selectedTab: state.selectedTab,
        isLibraryReports: action.payload,
      };
    case "SET_SELECTED_TAB":
      newState = {
        ...state,
        pagination: paginationInitialState,
        selectedTab: action.payload,
      };
      return {
        ...state,
        pagination: paginationInitialState,
        selectedTab: action.payload,
        reports: getModifiedReports(newState),
      };
    case "SET_FILTER_TYPE":
      newState = {
        ...state,
        pagination: paginationInitialState,
        filterType: action.payload,
      };
      return {
        ...newState,
        filterType: action.payload,
        reports: getModifiedReports(newState),
        count: getModifiedReportCount(newState),
      };
    case "SET_PAGINATION_PAGE":
      newState = {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
      };
    case "SET_PAGINATION_LIMIT":
      newState = {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.payload.limit,
        },
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
      };

    case "SET_SORT_TYPE":
      newState = {
        ...state,
        pagination: paginationInitialState,
        sortType: action.payload,
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
      };

    case "GET_ALL_ALIAS_ACCESSED_REPORTS":
      newState = {
        ...state,
        pagination: paginationInitialState,
        isAliasAccessedReports: action.payload,
      };
      return {
        ...newState,
        reports: getModifiedReports(newState),
      };

    default:
      return state;
  }
};

export const PurchaseHistoryContext = createContext();
export const usePurchaseHistoryCtx = () => useContext(PurchaseHistoryContext);
